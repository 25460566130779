import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import NoData from '../../../../components/NoData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';

function numberToDay (j) {
    return ('0' + j).slice(-2);
}

const AssetsInfo = (props) => {
    const titleData = props.partnerDetails && props.partnerDetails.titles;
    const mmmMetaData = [
        variables[props.lang].mmm_grade,
        variables[props.lang].custom_id,
        variables[props.lang].distributors,
        variables[props.lang].type,
        variables[props.lang].languages,
        variables[props.lang].video_on_demand,
        variables[props.lang].resolution,
        variables[props.lang].current_library,
        variables[props.lang].source_types,
        variables[props.lang].custom_source_types,
        variables[props.lang].sound,
        variables[props.lang].tags,
    ];
    const featureFilmMetaData = [
        variables[props.lang].release_date,
        variables[props.lang].release_year,
        variables[props.lang].duration,
        variables[props.lang].no_reels,
        variables[props.lang].imdb_uri,
        variables[props.lang].wiki_link,
        variables[props.lang].additional_links,
        variables[props.lang].feature_film_display_name,
        variables[props.lang].banners,
        variables[props.lang].genre,
        variables[props.lang].music_label,
        variables[props.lang].appreciation,
        variables[props.lang].release_status,
        variables[props.lang].synopsis,
        variables[props.lang].highlights_movie,
        variables[props.lang].production_type,
    ];
    const peopleMetaData = [
        variables[props.lang].directors,
        variables[props.lang].producers,
        variables[props.lang].lead_cast,
        variables[props.lang].other_cast,
        variables[props.lang].story_writer,
        variables[props.lang].screenplay,
        variables[props.lang].lyrics,
        variables[props.lang].dialogues,
        variables[props.lang].playback_singers,
        variables[props.lang].music_directors,
        variables[props.lang].background_score,
        variables[props.lang].sound_engineer,
    ];
    const supportingCrew = [
        variables[props.lang].editor,
        variables[props.lang].cinematography,
        variables[props.lang].dance_choreography,
        variables[props.lang].art_director,
        variables[props.lang].costumer,
        variables[props.lang].publicity,
        variables[props.lang].action_choreography,
        variables[props.lang].production_designer,
    ];

    return (
        <div className="assets_data">
            {titleData && titleData.length > 0
                ? titleData.map((item, index) => {
                    const sound = [];
                    if (item && item.sound &&
                        item.sound.tracks_5_1) {
                        sound.push({ value: '5.1 Tracks' });
                    }
                    if (item && item.sound &&
                        item.sound.it_tracks) {
                        sound.push({ value: 'IT Tracks' });
                    }
                    const originalTime = item.duration_before_censor
                        ? item.duration_before_censor
                        : 0;
                    const originalhour = Math.floor(originalTime / 3600);
                    const originalmin = Math.floor((originalTime - originalhour * 3600) / 60);
                    const originalSeconds = numberToDay(
                        Math.floor(originalTime - originalhour * 3600 - originalmin * 60),
                    );
                    const originalDuration =
                        numberToDay(originalhour) +
                        ': ' +
                        numberToDay(originalmin) +
                        ': ' +
                        originalSeconds;

                    const additionalLink = item.additional_link
                        ? item.additional_link
                        : '';
                    const split = additionalLink.split('\n');
                    const releaseDate =
                        item &&
                        item.release_date !== undefined
                            ? "'" +
                            item.release_date.month +
                            '/' +
                            item.release_date.date +
                            '/' +
                            item.release_date.year +
                            "'"
                            : '';
                    const mmmMetaInfo = [
                        [item ? item.grade : ''],
                        [item ? item.custom_id : ''],
                        [item && item.distributors
                            ? item.distributors
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.type],
                        [item && item.languages
                            ? item.languages
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.video_on_demand],
                        [item.resolution],
                        [item.current_or_library],
                        [item.source_types &&
                        item.source_types.length > 0
                            ? item.source_types
                                .map((item, i) => item)
                                .toString()
                                .replace(/,/g, ', ')
                            : []],
                        [item.custom_source_types &&
                        item.custom_source_types.length > 0
                            ? item.custom_source_types
                                .map((item, i) => item)
                                .toString()
                                .replace(/,/g, ', ')
                            : []],
                        [sound && sound.length > 0
                            ? sound.map((item) => item.value)
                            : []],
                        [item.tags &&
                        item.tags.length > 0
                            ? item.tags
                                .map((item, i) => item)
                                .toString()
                                .replace(/,/g, ', ')
                            : []],
                    ];

                    const supportingCrewInfo = [
                        [item.editors
                            ? item.editors
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.cinematography
                            ? item.cinematography
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.choreography
                            ? item.choreography
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.art_directors
                            ? item.art_directors
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.costumers
                            ? item.costumers
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.publicity
                            ? item.publicity
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.fights
                            ? item.fights
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.production_designers
                            ? item.production_designers
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                    ];

                    const featureFilmMetaInfo = [
                        [releaseDate !== '' ? moment(releaseDate).format('DD-MMM-YYYY') : ''],
                        [item.release_year],
                        [item.duration_before_censor ? originalDuration : ''],
                        [item.number_of_reels ? item.number_of_reels : ''],
                        [item.imdb_URL ? item.imdb_URL : ''],
                        [item.wiki_link ? item.wiki_link : ''],
                        split.length > 0
                            ? split.map((item, index) => {
                                return (
                                    <>
                                        {item}
                                        <br />
                                    </>
                                );
                            })
                            : '',
                        [item.name],
                        [item.banners
                            ? item.banners
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.genres
                            ? item.genres
                                .map((item, i) => item)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.music_labels
                            ? item.music_labels
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.appreciation
                            ? item.appreciation.split(',').map((item) => {
                                return (
                                    <>
                                        {item}
                                        <br />
                                    </>
                                );
                            })
                            : ''],
                        [<span
                            key={item}
                            style={{
                                color: item.release_status === true ? '#0FC02C' : ' #800000',
                            }}>
                            {' '}
                            {item.release_status === true ? 'Released' : 'Not Released'}{' '}
                        </span>],
                        [item.story],
                        [item.highlights],
                        [item.production_type],
                    ];
                    const peopleMetaInfo = [
                        [item.directors
                            ? item.directors
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.producers
                            ? item.producers
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.star_cast
                            ? item.star_cast
                                .map((item) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.cast
                            ? item.cast
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.story_writers
                            ? item.story_writers
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.screenplay
                            ? item.screenplay
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.lyrics
                            ? item.lyrics
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.dialogues
                            ? item.dialogues
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.playback_singers
                            ? item.playback_singers
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.music_directors
                            ? item.music_directors
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.background_score
                            ? item.background_score
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [item.sound_engineers
                            ? item.sound_engineers
                                .map((item, i) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                    ];
                    return (
                        <div key={index} className="info_section">
                            <Accordion className="accordion">
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    className="accordion_summary"
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1a-header">
                                    <h2>{item.name}</h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="info_section">
                                        <h2>{variables[props.lang]['mmm_meta_data']}</h2>
                                        {mmmMetaInfo && mmmMetaInfo.map((item1, index1) => {
                                            return (
                                                <div key={index1} className="section1">
                                                    <span>{mmmMetaData[index1]}</span>
                                                    <p>{item1}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="info_section">
                                        <h2>{variables[props.lang]['feature_film_meta_data']}</h2>
                                        {featureFilmMetaInfo && featureFilmMetaInfo.map((item1, index1) => {
                                            return (
                                                <div key={index1} className="section1">
                                                    <span>{featureFilmMetaData[index1]}</span>
                                                    <p>{item1}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="info_section">
                                        <h2>{variables[props.lang]['people_meta_data']}</h2>
                                        {peopleMetaInfo && peopleMetaInfo.map((item1, index1) => {
                                            return (
                                                <div key={index1} className="section1">
                                                    <span>{peopleMetaData[index1]}</span>
                                                    <p>{item1}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="info_section">
                                        <h2>{variables[props.lang]['supporting_crew']}</h2>
                                        {supportingCrewInfo && supportingCrewInfo.map((item1, index1) => {
                                            return (
                                                <div key={index1} className="section1">
                                                    <span>{supportingCrew[index1]}</span>
                                                    <p>{item1}</p>
                                                    {item1 &&
                                                        <CopyToClipboard
                                                            text={item1}>
                                                            <div className="copy_icon">
                                                                <Icon className="copy" icon="copy" />
                                                                {variables[props.lang].copy}
                                                            </div>
                                                        </CopyToClipboard>}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    );
                })
                : <NoData />}
        </div>
    );
};

AssetsInfo.propTypes = {
    lang: PropTypes.string.isRequired,
    partnerDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        partnerDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(AssetsInfo);
