import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import variables from '../../../../utils/variables';
import RightInfo from './RightInfo';
import Agreements from './Agreements';
import Partners from './Partners';
import Assets from './Assets';
import { fetchQuickViewDetails } from '../../../../actions/allTabs/quickview';
import CircularProgress from '../../../../components/CircularProgress';

const Tabs = (props) => {
    const [value, setValue] = useState('right_info');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleScroll = (value) => {
        if (props.rightsDetailsInProgress) {
            return;
        }
        const count = value === 'assets'
            ? props.rightDetails && props.rightDetails.count && props.rightDetails.count.titles_count
            : value === 'partners'
                ? props.rightDetails && props.rightDetails.count && props.rightDetails.count.partners_count
                : value === 'agreements'
                    ? props.rightDetails && props.rightDetails.count && props.rightDetails.count.agreements_count
                    : 0;
        const listLength = value === 'assets'
            ? props.rightDetails && props.rightDetails.list && props.rightDetails.list.titles && props.rightDetails.list.titles.length
            : value === 'partners'
                ? props.rightDetails && props.rightDetails.list && props.rightDetails.list.partners && props.rightDetails.list.partners.length
                : value === 'agreements'
                    ? props.rightDetails && props.rightDetails.list && props.rightDetails.list.agreements && props.rightDetails.list.agreements.length
                    : 0;
        if (listLength < count) {
            props.fetchQuickViewDetails('rights', props.rightId, listLength, props.limit, value);
        }
    };

    const assets = props.rightDetails && props.rightDetails.count &&
        props.rightDetails.count.titles_count;
    const agreements = props.rightDetails && props.rightDetails.count &&
        props.rightDetails.count.agreements_count;
    const partners = props.rightDetails && props.rightDetails.count &&
        props.rightDetails.count.partners_count;

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext className="tab_context" value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList className="tab_list" onChange={handleChange}>
                        <Tab
                            className={value === 'right_info' ? 'active_list' : ''}
                            label={variables[props.lang]['right_info']}
                            value="right_info" />
                        <Tab
                            className={value === 'assets' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].assets}
                                {assets ? ' (' + assets + ')' : null}
                            </>}
                            value="assets" />
                        <Tab
                            className={value === 'partners' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].partners}
                                {partners ? ' (' + partners + ')' : null}
                            </>}
                            value="partners" />
                        <Tab
                            className={value === 'agreements' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].agreements}
                                {agreements ? ' (' + agreements + ')' : null}
                            </>}
                            value="agreements" />
                    </TabList>
                </Box>
                <TabPanel className="assets_info" value="right_info">
                    {props.rightsDetailsInProgress ? <CircularProgress/> : <RightInfo />}
                </TabPanel>
                <TabPanel className="assets_info" value="assets" onScroll={() => handleScroll('assets')}>
                    <Assets />
                    {props.rightsDetailsInProgress ? <CircularProgress/> : ''}
                </TabPanel>
                <TabPanel className="assets_info" value="partners" onScroll={() => handleScroll('partners')}>
                    <Partners />
                    {props.rightsDetailsInProgress ? <CircularProgress/> : ''}
                </TabPanel>
                <TabPanel className="assets_info" value="agreements" onScroll={() => handleScroll('agreements')}>
                    <Agreements />
                    {props.rightsDetailsInProgress ? <CircularProgress/> : ''}
                </TabPanel>
            </TabContext>
        </Box>
    );
};

Tabs.propTypes = {
    fetchQuickViewDetails: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    rightDetails: PropTypes.object.isRequired,
    rightId: PropTypes.string.isRequired,
    rightsDetailsInProgress: PropTypes.bool.isRequired,
    skip: PropTypes.number.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        rightDetails: state.allTabs.quickview.quickViewDetails.value,
        rightId: state.allTabs.quickview.rightId.value,
        skip: state.allTabs.quickview.quickViewDetails.skip,
        limit: state.allTabs.quickview.quickViewDetails.limit,
        rightsDetailsInProgress: state.allTabs.quickview.quickViewDetails.inProgress,
    };
};

const actionToProps = {
    fetchQuickViewDetails,
};

export default connect(stateToProps, actionToProps)(Tabs);
