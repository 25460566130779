export const QUICK_VIEW_SHOW = 'QUICK_VIEW_SHOW';
export const QUICK_VIEW_HIDE = 'QUICK_VIEW_HIDE';

export const FETCH_QUICK_VIEW_DETAILS_SUCCESS = 'FETCH_QUICK_VIEW_DETAILS_SUCCESS';
export const FETCH_QUICK_VIEW_DETAILS_IN_PROGRESS = 'FETCH_QUICK_VIEW_DETAILS_IN_PROGRESS';
export const FETCH_QUICK_VIEW_DETAILS_ERROR = 'FETCH_QUICK_VIEW_DETAILS_ERROR';

export const FETCH_PROJECT_DETAILS_SUCCESS = 'FETCH_PROJECT_DETAILS_SUCCESS';
export const FETCH_PROJECT_DETAILS_IN_PROGRESS = 'FETCH_PROJECT_DETAILS_IN_PROGRESS';
export const FETCH_PROJECT_DETAILS_ERROR = 'FETCH_PROJECT_DETAILS_ERROR';

export const FETCH_DETAILS_EXPORT_SUCCESS = 'FETCH_DETAILS_EXPORT_SUCCESS';
export const FETCH_DETAILS_EXPORT_IN_PROGRESS = 'FETCH_DETAILS_EXPORT_IN_PROGRESS';
export const FETCH_DETAILS_EXPORT_ERROR = 'FETCH_DETAILS_EXPORT_ERROR';

export const FETCH_DOWNLOAD_FILE_SUCCESS = 'FETCH_DOWNLOAD_FILE_SUCCESS';
export const FETCH_DOWNLOAD_FILE_IN_PROGRESS = 'FETCH_DOWNLOAD_FILE_IN_PROGRESS';
export const FETCH_DOWNLOAD_FILE_ERROR = 'FETCH_DOWNLOAD_FILE_ERROR';

export const RIGHTS_OVERVIEW_ID_SET = 'RIGHTS_OVERVIEW_ID_SET';
