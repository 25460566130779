import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import variables from '../../../../utils/variables';
import PartnerInfo from './PartnerInfo';
import Agreements from './Agreements';
import Assets from './Assets';
import Rights from './Rights';

const Tabs = (props) => {
    const [value, setValue] = useState('partner_info');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const assets = props.partnerDetails && props.partnerDetails.titles &&
        props.partnerDetails.titles.length;
    const agreements = props.partnerDetails && props.partnerDetails.agreements &&
        props.partnerDetails.agreements.length;
    const rights = props.partnerDetails && props.partnerDetails.rights &&
        props.partnerDetails.rights.length;

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext className="tab_context" value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList className="tab_list" onChange={handleChange}>
                        <Tab
                            className={value === 'partner_info' ? 'active_list' : ''}
                            label={variables[props.lang]['partner_info']}
                            value="partner_info" />
                        <Tab
                            className={value === 'assets' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].assets}
                                {assets ? ' (' + assets + ')' : null}
                            </>}
                            value="assets" />
                        <Tab
                            className={value === 'agreements' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].agreements}
                                {agreements ? ' (' + agreements + ')' : null}
                            </>}
                            value="agreements" />
                        <Tab
                            className={value === 'rights' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].rights}
                                {rights ? ' (' + rights + ')' : null}
                            </>}
                            value="rights" />
                        <Tab
                            className={value === 'financial_info' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].financial_info}
                            </>}
                            value="financial_info" />
                        <Tab
                            className={value === 'contacts' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].contacts}
                            </>}
                            value="contacts" />
                    </TabList>
                </Box>
                <TabPanel className="assets_info" value="partner_info">
                    <PartnerInfo />
                </TabPanel>
                <TabPanel className="assets_info" value="assets">
                    <Assets />
                </TabPanel>
                <TabPanel className="assets_info" value="agreements">
                    <Agreements />
                </TabPanel>
                <TabPanel className="assets_info" value="rights">
                    <Rights />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

Tabs.propTypes = {
    lang: PropTypes.string.isRequired,
    partnerDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        partnerDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Tabs);
