import { combineReducers } from 'redux';
import {
    FETCH_DETAILS_EXPORT_ERROR,
    FETCH_DETAILS_EXPORT_IN_PROGRESS,
    FETCH_DETAILS_EXPORT_SUCCESS,
    FETCH_PROJECT_DETAILS_ERROR,
    FETCH_PROJECT_DETAILS_IN_PROGRESS,
    FETCH_PROJECT_DETAILS_SUCCESS,
    FETCH_QUICK_VIEW_DETAILS_ERROR,
    FETCH_QUICK_VIEW_DETAILS_IN_PROGRESS,
    FETCH_QUICK_VIEW_DETAILS_SUCCESS,
    RIGHTS_OVERVIEW_ID_SET,
    QUICK_VIEW_HIDE,
    QUICK_VIEW_SHOW,
} from '../../constants/allTabs/quickview';
import { DISCONNECT_SET } from '../../constants/profile';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';

const drawer = (state = {
    open: false,
    agreementOpen: false,
}, action) => {
    switch (action.type) {
    case QUICK_VIEW_SHOW:
        return {
            ...state,
            open: true,
        };
    case QUICK_VIEW_HIDE:
        return {
            ...state,
            open: false,
        };

    default:
        return state;
    }
};

const quickViewDetails = (state = {
    inProgress: false,
    value: {},
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    skipAssets: 0,
    skipAgreements: 0,
    skipPartners: 0,
}, action) => {
    switch (action.type) {
    case FETCH_QUICK_VIEW_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_QUICK_VIEW_DETAILS_SUCCESS: {
        let updatedValue = { ...state.value };
        let updatedSkip = state.skip;

        if (action.value && action.value.list) {
            if (action.tab === 'assets' && action.value.list.titles) {
                updatedValue.list = {
                    ...state.value.list,
                    titles: [
                        ...(state.value.list.titles || []),
                        ...action.value.list.titles,
                    ],
                };
                updatedSkip = state.skipAssets + action.value.list.titles.length;
            } else if (action.tab === 'partners' && action.value.list.partners) {
                updatedValue.list = {
                    ...state.value.list,
                    partners: [
                        ...(state.value.list.partners || []),
                        ...action.value.list.partners,
                    ],
                };
                updatedSkip = state.skipPartners + action.value.list.partners.length;
            } else if (action.tab === 'agreements' && action.value.list.agreements) {
                updatedValue.list = {
                    ...state.value.list,
                    agreements: [
                        ...(state.value.list.agreements || []),
                        ...action.value.list.agreements,
                    ],
                };
                updatedSkip = state.skipAgreements + action.value.list.agreements.length;
            } else {
                updatedValue = action.value;
            }
        } else {
            updatedValue = action.value;
        }

        return {
            ...state,
            inProgress: false,
            value: updatedValue,
            skip: updatedSkip || 0,
            limit: action.limit,
            skipAssets: action.tab === 'assets' ? updatedSkip : state.skipAssets,
            skipAgreements: action.tab === 'agreements' ? updatedSkip : state.skipAgreements,
            skipPartners: action.tab === 'partners' ? updatedSkip : state.skipPartners,
        };
    }
    case FETCH_QUICK_VIEW_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const exportDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_DETAILS_EXPORT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_DETAILS_EXPORT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_DETAILS_EXPORT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const projectDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROJECT_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROJECT_DETAILS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_PROJECT_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const rightId = (state = {
    value: null,
}, action) => {
    switch (action.type) {
    case RIGHTS_OVERVIEW_ID_SET:
        return {
            ...state,
            value: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    drawer,
    quickViewDetails,
    exportDetails,
    projectDetails,
    rightId,
});
