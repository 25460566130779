import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import GroupInfo from './GroupInfo';
import VendorsInfo from './VendorsInfo';

const AgreementInfo = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [colors, setColors] = useState([
        '#EE7D2B', '#127681', '#E24759', '#3246FF',
        '#FF0000', '#9500AD', '#FF5C00', '#4343A4',
    ]);
    // eslint-disable-next-line no-unused-vars
    const [seletedColors, setseletedColors] = useState({});

    const agreementData = props.agreementDetails && props.agreementDetails.agreement;
    const agreementInfo = [
        variables[props.lang].agreement_name,
        variables[props.lang].agreement_category,
        variables[props.lang].agreement_type,
        variables[props.lang].tenure,
        variables[props.lang].agreement_date,
        variables[props.lang].start_date,
        variables[props.lang].end_date,
        variables[props.lang].assets,
        variables[props.lang].assignors,
        variables[props.lang].assignees,
        variables[props.lang].additional_info,
        variables[props.lang].rights_group,
        variables[props.lang].tags,
    ];

    const mmmMetaInfo = agreementData && agreementData
        ? [
            agreementData.agreement_id ? agreementData.agreement_id : '',
            agreementData.category,
            agreementData.type,
            agreementData.tenure,
            agreementData.date
                ? moment(agreementData.date).format('DD-MMM-YYYY')
                : null,
            agreementData.start_date
                ? moment(agreementData.start_date).format(
                    'DD-MMM-YYYY',
                )
                : null,
            agreementData.end_date
                ? moment(agreementData.end_date).format(
                    'DD-MMM-YYYY',
                )
                : null,
            agreementData &&
            agreementData.assets &&
            agreementData.assets.length > 0
                ? agreementData.assets
                    .map((item) => item.name)
                    .toString()
                    .replace(/,/g, ', ')
                : [],
            agreementData &&
            agreementData.vendors &&
            agreementData.vendors.length > 0
                ? agreementData.vendors
                    .map((item) => item.official_name)
                    .toString()
                    .replace(/,/g, ', ')
                : [],
            agreementData &&
            agreementData.assignees &&
            agreementData.assignees.length > 0
                ? agreementData.assignees
                    .map((item) => item.official_name)
                    .toString()
                    .replace(/,/g, ', ')
                : [],
            agreementData.description ? agreementData.description : '',
            <div key={0} className="agreement_groups">
                {
                    agreementData &&
                    agreementData.right_groups &&
                    agreementData.right_groups.length > 0
                        ? agreementData.right_groups
                            .map((item, index) =>
                                <div
                                    key={index} className={
                                        item.name === 'Linear' ? 'linear_group common_group'
                                            : item.name === 'Non-Linear' ? 'non_linear_group common_group'
                                                : item.name === 'Physical' ? 'physical_group common_group'
                                                    : item.name === 'Ancillary' ? 'ancillary_group common_group' : 'common_group'}>
                                    {item.name}
                                </div>)
                        : []
                }
            </div>,
            agreementData &&
            agreementData.tags &&
            agreementData.tags.length > 0
                ? agreementData.tags
                    .map((chip, chipIndex) => {
                        let randomColor = colors[Math.floor(
                            Math.random() * colors.length)];

                        if (seletedColors && Object.keys(seletedColors).length &&
                            seletedColors[chip + chip._id]) {
                            randomColor = seletedColors[chip + chip._id];
                        }

                        return (
                            <div
                                key={chipIndex}
                                className={agreementData.tags.length > 3 ? 'chip_back_qv' : 'chip_back_qv chip_back_length'}
                                style={{ background: randomColor }}
                                title={chip.name}>
                                {chip.name}
                            </div>
                        );
                    })
                : [],
        ]
        : null;

    return (
        <div className="info_section">
            {mmmMetaInfo && mmmMetaInfo.map((item, index) => {
                return (
                    <div key={index} className="section1">
                        <span>{agreementInfo[index]}</span>
                        <p>{item}</p>
                        {item &&
                            <CopyToClipboard
                                text={item}>
                                <div className="copy_icon">
                                    <Icon className="copy" icon="copy" />
                                    {variables[props.lang].copy}
                                </div>
                            </CopyToClipboard>}
                    </div>
                );
            })}
            {agreementData &&
            agreementData.groups &&
            agreementData.groups.length > 0 ? (
                    agreementData.groups.map(
                        (item, index) => {
                            const rightGroup = agreementData &&
                            agreementData.right_groups &&
                            agreementData.right_groups.length > 0 &&
                            agreementData.right_groups.filter((right) =>
                                right._id === (item && item._id && item._id._id));
                            if (rightGroup && rightGroup.length > 0) {
                                return (
                                    <Accordion key={index} className="accordion">
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            className="accordion_summary"
                                            expandIcon={<ExpandMoreIcon/>}
                                            id="panel1a-header">
                                            <h2>{item && item._id && item._id.name}</h2>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <GroupInfo data={item} />
                                            {item && item.vendors && item.vendors.length > 0 &&
                                            <VendorsInfo data={item} />}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            }
                        }))
                : null}
        </div>
    );
};

AgreementInfo.propTypes = {
    agreementDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        agreementDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(AgreementInfo);
